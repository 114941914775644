import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import Homepages from './components/home/Homepages';
import Register from './components/Register';
import Login from "./components/LoginSponsor";
import AdminDashboard from './components/adminDashboard/AdminDashboard';
import ViewNewsId from './components/adminDashboard/ViewNewsId';
import CategoryCards from './components/CategoryCards';
import AboutUs from './components/common/AboutUs/AboutUs';
import i18n from './i18n'; // Import your i18n configuration
import NotFound from './components/NotFound';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/category/:category" element={<><Header /><CategoryCards /><Footer /></>} />
          <Route path="/home" element={<><Header /><Homepages /><Footer /></>} />
          <Route path="/singlepage/:id" element={<><Header /><ViewNewsId /><Footer /></>} />
          <Route path="/register" element={<><Header /><Register /><Footer /></>} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/sponsor register" element={<><Header /><Login /><Footer /></>} />
          <Route path="/about-us" element={<><Header /><AboutUs /><Footer /></>} />
          <Route path="*" element={<NotFound />} />
          </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;
