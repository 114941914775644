import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';


const AboutUs = () => {
    const { t, i18n } = useTranslation();

    

    return (
        <>
        <Helmet>
                <title>{i18n.language === 'ar' ? 'من نحن' : 'About Us'}</title>
                <meta name="description" content={i18n.language === 'ar' 
                    ? 'موقع توليب نيوز هو أول موقع إخباري تونسي ليبي شامل، يهدف إلى تقديم تغطية شاملة لأهم الأحداث والأخبار في تونس وليبيا والعالم بأسره.' 
                    : 'Tulip News is the first comprehensive Tunisian-Libyan news website, aiming to provide comprehensive coverage of the most important events and news in Tunisia, Libya, and the entire world.'} 
                />
                <meta property="og:title" content={i18n.language === 'ar' ? 'من نحن' : 'About Us'} />
                <meta property="og:description" content={i18n.language === 'ar' 
                    ? 'موقع توليب نيوز هو أول موقع إخباري تونسي ليبي شامل، يهدف إلى تقديم تغطية شاملة لأهم الأحداث والأخبار في تونس وليبيا والعالم بأسره.' 
                    : 'Tulip News is the first comprehensive Tunisian-Libyan news website, aiming to provide comprehensive coverage of the most important events and news in Tunisia, Libya, and the entire world.'} 
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={i18n.language === 'ar' ? 'من نحن' : 'About Us'} />
                <meta name="twitter:description" content={i18n.language === 'ar' 
                    ? 'موقع توليب نيوز هو أول موقع إخباري تونسي ليبي شامل، يهدف إلى تقديم تغطية شاملة لأهم الأحداث والأخبار في تونس وليبيا والعالم بأسره.' 
                    : 'Tulip News is the first comprehensive Tunisian-Libyan news website, aiming to provide comprehensive coverage of the most important events and news in Tunisia, Libya, and the entire world.'} 
                />
            </Helmet>
        <div className={`max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
            
            <section className='mb-8'>
                <h2 className='text-2xl font-bold text-gray-800 mb-4'>{i18n.language === 'ar' ? 'من نحن' : 'About Us'}</h2>
                <p className='text-gray-600 leading-relaxed'>
                    {i18n.language === 'ar' ? 
                    'موقع توليب نيوز هو أول موقع إخباري تونسي ليبي شامل، يهدف إلى تقديم تغطية شاملة لأهم الأحداث والأخبار في تونس وليبيا والعالم بأسره. نحن فريق من الصحفيين المحترفين والمحررين ذوي الخبرة، نعمل بجد لتزويدكم بالأخبار الدقيقة والتحليلات العميقة.' : 
                    'Tulip News is the first comprehensive Tunisian-Libyan news website, aiming to provide comprehensive coverage of the most important events and news in Tunisia, Libya, and the entire world. We are a team of professional journalists and experienced editors, working hard to provide you with accurate news and in-depth analysis.'}
                </p>
            </section>

            <section className='mb-8'>
                <h3 className='text-xl font-semibold text-gray-700 mb-2'>{i18n.language === 'ar' ? 'مهمتنا:' : 'Our Mission:'}</h3>
                <ul className='list-disc list-inside space-y-2 text-gray-600'>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'الشفافية:' : 'Transparency:'}</strong> {i18n.language === 'ar' ? 'تقديم الأخبار والمعلومات بكل حيادية وصدق.' : 'Providing news and information with neutrality and honesty.'}</li>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'التنوع:' : 'Diversity:'}</strong> {i18n.language === 'ar' ? 'تغطية واسعة تشمل السياسة، الاقتصاد، الثقافة، الرياضة، التكنولوجيا، والمزيد.' : 'Wide coverage including politics, economy, culture, sports, technology, and more.'}</li>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'التحديث المستمر:' : 'Continuous Updates:'}</strong> {i18n.language === 'ar' ? 'تقديم الأخبار العاجلة والتقارير الفورية لضمان بقاءكم على اطلاع دائم بكل ما يحدث.' : 'Providing breaking news and instant reports to keep you updated with everything happening.'}</li>
                </ul>
            </section>

            <section className='mb-8'>
                <h3 className='text-xl font-semibold text-gray-700 mb-2'>{i18n.language === 'ar' ? 'ما نقدمه:' : 'What We Offer:'}</h3>
                <ul className='list-disc list-inside space-y-2 text-gray-600'>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'الأخبار المحلية:' : 'Local News:'}</strong> {i18n.language === 'ar' ? 'تغطية متميزة لأهم الأحداث في تونس وليبيا، مع التركيز على القضايا التي تهم المواطنين.' : 'Outstanding coverage of the most important events in Tunisia and Libya, focusing on issues that concern citizens.'}</li>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'الأخبار العالمية:' : 'Global News:'}</strong> {i18n.language === 'ar' ? 'متابعة لأبرز المستجدات العالمية، مع تحليلات معمقة من خبرائنا.' : 'Following the latest global developments, with in-depth analysis from our experts.'}</li>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'التقارير والتحليلات:' : 'Reports and Analyses:'}</strong> {i18n.language === 'ar' ? 'تقديم تقارير مفصلة وتحليلات دقيقة للأحداث الكبرى لتوضيح السياق وتقديم رؤى أعمق.' : 'Providing detailed reports and precise analyses of major events to clarify context and offer deeper insights.'}</li>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? 'الوسائط المتعددة:' : 'Multimedia:'}</strong> {i18n.language === 'ar' ? 'توفير محتوى متنوع يشمل المقالات، الصور، الفيديوهات، والإنفوجرافيك.' : 'Providing diverse content, including articles, images, videos, and infographics.'}</li>
                </ul>
            </section>
            <section className='mb-8'>
                <h3 className='text-xl font-semibold text-gray-700 mb-2'>{i18n.language === 'ar' ? ' الإدارة:' : 'Administration:'}</h3>
                <ul className='list-disc list-inside space-y-2 text-gray-600'>
                    <li><strong className='font-semibold'>{i18n.language === 'ar' ? ' المؤسس و المدير المسؤول:' : 'Founder and Managing Director:'}</strong> <strong className='font-bold'> {i18n.language === 'ar' ? 'وليد عبد الله.' : 'Walid Abdallah.'} </strong></li>
                </ul>
            </section>

            <section className='mb-8'> 
                <h3 className='text-xl font-semibold text-gray-700 mb-2'>{i18n.language === 'ar' ? 'انضموا إلينا:' : 'Join Us:'}</h3>
                <p className='text-gray-600 leading-relaxed'>
                    {i18n.language === 'ar' ? 
                    'ندعوكم للانضمام إلى مجتمع توليب نيوز، حيث يمكنكم متابعة آخر الأخبار والتقارير، والمشاركة في النقاشات، ومشاركة آرائكم وتعليقاتكم. نحن هنا لنسمعكم ولنعمل معًا على تقديم محتوى يلبي تطلعاتكم واحتياجاتكم.' : 
                    'We invite you to join the Tulip News community, where you can follow the latest news and reports, participate in discussions, and share your opinions and comments. We are here to listen to you and work together to provide content that meets your aspirations and needs.'}
                </p>
                <p className='text-gray-600'>{i18n.language === 'ar' ? 'تابعونا على [روابط وسائل التواصل الاجتماعي] لتحصلوا على تحديثات فورية وآنية.' : 'Follow us on [social media links] for instant and timely updates.'}</p>
            </section>

            <section>
                <h3 className='text-xl font-semibold text-gray-700 mb-2'>{i18n.language === 'ar' ? 'اتصل بنا:' : 'Contact Us:'}</h3>
                <p className='text-gray-600 mb-2'>{i18n.language === 'ar' ? 'إذا كان لديكم أي استفسارات أو اقتراحات، لا تترددوا في الاتصال بنا عبر:' : 'If you have any inquiries or suggestions, do not hesitate to contact us via:'}</p>
                <ul className='list-disc list-inside space-y-2 text-gray-600'>
                    <li><a href="mailto:tulibnews@gmail.com" className='text-blue-500 hover:underline'>tulibnews@gmail.com</a></li>
                    <li ><span dir="ltr"> (+216) 23 050 630 </span></li>
                    <li ><span dir="ltr"> (+218) 946 986 575 </span></li>
                </ul>
            </section>
        </div>
        </>
    );
}

export default AboutUs;
