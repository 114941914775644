import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaLink,
  FaTelegramPlane,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiOutlineShare } from "react-icons/hi";
import { Helmet } from "react-helmet-async";
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const ViewNewsId = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const viewLanguage = i18n.language;

  const extractYouTubeID = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(u\/w\/)|(embed\/)|(watch\?v=))([^"&?\/\s]{11})/;
    const match = url.match(regExp);
    return match ? match[7] : null;
  };

  const [fields, setFields] = useState({
    title: { en: "", ar: "" },
    mainParagraph: { en: "", ar: "" },
    subtitles: [],
    creator: { name: { en: "", ar: "" }, image: "" },
    videoUrl: "",
    createdAt: "",
    category: "",
  });
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(true);
  const [randomArticles, setRandomArticles] = useState([]);
  const [showShareMenu, setShowShareMenu] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${API_URL}/api/articles/getNew/${id}`);
        const article = await response.json();
        // console.log("Fetched article:", article);
        setFields({
          title: article.title,
          mainParagraph: article.mainParagraph,
          subtitles: article.subtitles,
          creator: article.creator,
          createdAt: article.createdAt,
          videoUrl: article.videoUrl,
          category: article.category,
        });
        setImagePreview(
          article.image ? `${API_URL}/${article?.image.replace("\\", "/")}` : ""
        );
        setLoading(false);

        // Fetch random articles from the same category and week
        const weekStart = new Date(article.createdAt);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Start of the week
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6); // End of the week

        const randomResponse = await axios.get(
          `${API_URL}/api/articles/random`,
          {
            params: {
              category: article.category,
              startDate: weekStart.toISOString(),
              endDate: weekEnd.toISOString(),
            },
          }
        );
        // console.log("randomResponse", randomResponse);
        setRandomArticles(randomResponse.data.articles);
      } catch (error) {
        console.error("Failed to fetch article:", error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const formatParagraphs = (text) => {
    return text.split("\n").map((para, index) => (
      <p key={index} className="text-2xl mb-4 leading-relaxed">
        {para}
      </p>
    ));
  };

  const videoID = fields.videoUrl ? extractYouTubeID(fields.videoUrl) : "";

  const shareOnSocialMedia = (platform) => {
    const url = window.location.href;
    const title = fields.title?.[viewLanguage] || "Default Title";
    const image = imagePreview || ""; // Assuming imagePreview contains the article's image URL

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}&quote=${encodeURIComponent(title)}`,
          "_blank"
        );
        break;

      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            url
          )}&text=${encodeURIComponent(title)}`,
          "_blank"
        );
        break;

      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url
          )}&title=${encodeURIComponent(
            title
          )}&summary=${encodeURIComponent(title)}&source=${encodeURIComponent(
            url
          )}&image=${encodeURIComponent(image)}`,
          "_blank"
        );
        break;

      case "instagram":
        window.open(
          `https://www.instagram.com/share?url=${encodeURIComponent(url)}`,
          "_blank"
        );
        break;

      case "tiktok":
        window.open(
          `https://www.tiktok.com/share?url=${encodeURIComponent(url)}`,
          "_blank"
        );
        break;

      case "telegram":
        window.open(
          `https://t.me/share/url?url=${encodeURIComponent(
            url
          )}&text=${encodeURIComponent(title)}`,
          "_blank"
        );
        break;

      default:
        break;
    }
  };
  

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("Link copied to clipboard!");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader"></div> {/* Add your loader styling here */}
      </div>
    );
  }
  const title = fields.title?.[viewLanguage] || "Default Title";
  const mainParagraph =
    fields.mainParagraph?.[viewLanguage] || "Default Paragraph";

  const extractVideoId = (url) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={mainParagraph.substring(0, 150)} />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={mainParagraph.substring(0, 150)}
        />
        <meta property="og:image" content={imagePreview} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={mainParagraph.substring(0, 150)}
        />
        <meta name="twitter:image" content={imagePreview} />
      </Helmet>
      <div
        className={`flex flex-col lg:flex-row lg:items-start items-center justify-center p-6 lg:p-12 bg-gray-100 rounded-lg shadow-lg`}
        dir={viewLanguage === "en" ? "ltr" : "rtl"}
      >
        {/* Article Content */}
        <div className="w-full lg:w-2/3 bg-white p-6 rounded-lg shadow-md">
          <h1
            className="text-3xl font-semibold mb-4"
            dir={viewLanguage === "en" ? "ltr" : "rtl"}
          >
            {t(`title.${viewLanguage}`, {
              defaultValue: fields.title[viewLanguage],
            })}
          </h1>
          {fields.creator?.image && (
            <div className="flex gap-4 items-center mb-6">
              <img
                src={
                  fields.creator?.image
                    ? `${API_URL}/${fields.creator.image.replace("\\", "/")}`
                    : ""
                }
                alt="Creator"
                className="w-10 h-10 rounded-full mr-3"
              />
              <span
                className="text-xl font-bold text-gray-600"
                dir={viewLanguage === "en" ? "ltr" : "rtl"}
              >
                {viewLanguage === "ar"
                  ? fields.creator?.username?.ar || fields.creator?.username
                  : fields.creator?.username?.en || fields.creator?.username}
              </span>
            </div>
          )}
          <div className="author flex">
            <span>{new Date(fields.createdAt).toLocaleDateString()}</span>
          </div>
          <div className="mb-6">
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Article Preview"
                className="w-full h-auto rounded-lg shadow-sm"
              />
            )}
            {fields.videoUrl && videoID && (
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${videoID}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {fields.mainParagraph && (
              <div className="mb-6" dir={viewLanguage === "en" ? "ltr" : "rtl"}>
                {formatParagraphs(
                  t(`mainParagraph.${viewLanguage}`, {
                    defaultValue: fields.mainParagraph[viewLanguage],
                  })
                )}
              </div>
            )}
            {fields.subtitles &&
              fields.subtitles.map((subtitle, index) => (
                <div
                  key={index}
                  className="mb-6"
                  dir={viewLanguage === "en" ? "ltr" : "rtl"}
                >
                  <h4 className="text-xl font-semibold mb-2">
                    {t(`subtitle.${index}.${viewLanguage}`, {
                      defaultValue: subtitle.subtitle[viewLanguage],
                    })}
                  </h4>
                  {formatParagraphs(
                    t(`paragraph.${index}.${viewLanguage}`, {
                      defaultValue: subtitle.paragraph[viewLanguage],
                    })
                  )}
                </div>
              ))}
          </div>
          {/* Share Button with Dropdown Menu */}
          {/* Share Button with Dropdown Menu */}
          <div className="relative inline-block text-left">
            <button
              onClick={() => setShowShareMenu(!showShareMenu)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
            >
              <HiOutlineShare className="mr-2" /> {t("Share")}
            </button>
            {showShareMenu && (
              <div
                className={`absolute ${i18n.language === "ar" ? "right-0" : "left-0"} mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10`}
              >
                <ul className="py-2">
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("facebook")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaFacebookF className="mr-2" /> Facebook
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("twitter")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaXTwitter className="mr-2" /> Twitter
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("linkedin")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaLinkedinIn className="mr-2" /> LinkedIn
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("telegram")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaTelegramPlane className="mr-2" /> Telegram
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("instagram")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaInstagram className="mr-2" /> Instagram
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => shareOnSocialMedia("tiktok")}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaTiktok className="mr-2" /> TikTok
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={copyLink}
                      className="flex items-center px-4 py-2 hover:bg-gray-100 w-full text-left"
                    >
                      <FaLink className="mr-2" /> Copy Link
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Read More Section */}
        <div className="w-full lg:w-1/3 p-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            {t("Read more")}
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {randomArticles.length === 0 ? (
              <p className="text-gray-600">{t("noMoreArticles")}</p>
            ) : (
              randomArticles.map((article) => (
                <Link
                  to={`/singlepage/${article._id}`}
                  key={article._id}
                  className="block"
                >
                  <div className="cursor-pointer">
                    {article.category === "VideoGraphs" ? (
                      <img
                        src={
                          article.videoUrl
                            ? `https://img.youtube.com/vi/${extractVideoId(article.videoUrl)}/hqdefault.jpg`
                            : ""
                        }
                        alt={
                          i18n.language === "ar"
                            ? article.title.ar
                            : article.title.en
                        }
                        className="w-full h-48 object-cover mb-4"
                      />
                    ) : (
                      <img
                        src={
                          article.image
                            ? `${API_URL}/${article.image.replace("\\", "/")}`
                            : ""
                        }
                        alt={
                          i18n.language === "ar"
                            ? article.title.ar
                            : article.title.en
                        }
                        className="w-full h-48 object-cover mb-4"
                      />
                    )}{" "}
                    <h3 className="text-xl font-semibold mb-2">
                      {article.title[viewLanguage]}
                    </h3>
                    <p className="text-sm text-gray-600">
                      {new Date(article.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNewsId;
