import React, { useState } from "react";
import axios from "axios";
import { FaUser, FaLock, FaEnvelope, FaCheck } from "react-icons/fa"; // Removed FaCheck since we're no longer using it
import { useAuth } from "./AuthContext";
import { Navigate } from "react-router-dom";

import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;

const Register = () => {
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false);
  const [isResetCodeSent, setIsResetCodeSent] = useState(false);
  const [isResetCodeVerified, setIsResetCodeVerified] = useState(false);

  const [usernameAr, setUsernameAr] = useState("");
  const [usernameEn, setUsernameEn] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPW, setConfirmPassword] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [forgotEmail, setForgotEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [verifResetCode, setVerifResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [message, setMessage] = useState("");

  const { login } = useAuth();
  const token = localStorage.getItem("token");

  if (token) {
    return <Navigate to="/" />;
  }

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/api/register`, {
        username: {
          ar: usernameAr,
          en: usernameEn,
        },
        email,
        password,
        confirmPW,
      });

      setMessage(response.data.message);
      setConfirmPassword("");
      setPassword("");
      setEmail("");
      setUsernameAr("");
      setUsernameEn("");
      setIsRegisterMode(false);
    } catch (error) {
      setMessage("Error during registration.");
      console.error(error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/api/login`, {
        email: loginEmail,
        password: loginPassword,
      });

      login(response.data.token, response.data.user);
      setMessage("Login success");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Your account is blocked or not yet active.");
      } else {
        setMessage("Error during login something Wrong.");
      }
      console.error(error);
    }
  };

  const handleSendResetCode = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/api/forgot-password`, {
        email: forgotEmail,
      });
      setVerifResetCode(response.data.resetCode);
      setMessage(response.data.message);
      setIsResetCodeSent(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("This email does not exist.");
      } else {
        setMessage("Error during password reset.");
      }
    }
  };

  const handleVerifyResetCode = async (e) => {
    e.preventDefault();

    if (resetCode === verifResetCode) {
      setIsResetCodeVerified(true);
      setMessage("Reset code verified.");
    } else {
      setMessage("Invalid reset code.");
    }
  };

  const handleSetNewPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/api/reset-password`, {
        email: forgotEmail,
        newPassword,
        confirmNewPassword,
      });

      setMessage(response.data.message);
      setIsForgotPasswordMode(false);
      setIsResetCodeSent(false);
      setIsResetCodeVerified(false);
      setForgotEmail("");
      setResetCode("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setMessage("Error resetting password.");
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-lg">
      <div className="bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center">
          {isRegisterMode
            ? "Register"
            : isForgotPasswordMode
            ? isResetCodeSent
              ? "Set New Password"
              : "Forgot Password"
            : "Login"}
        </h1>

        {message && (
          <div
            className={`mb-4 p-3 rounded ${
              message.includes("Error")
                ? "bg-red-100 text-red-700"
                : "bg-green-100 text-green-700"
            }`}
          >
            {message}
          </div>
        )}

        {isForgotPasswordMode ? (
          !isResetCodeSent ? (
            <form onSubmit={handleSendResetCode} className="space-y-4">
              <div className="relative">
                <span className="absolute left-3 top-3">
                  <FaEnvelope className="text-gray-500" />
                </span>
                <input
                  type="email"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                  placeholder="Your Email"
                  value={forgotEmail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition"
              >
                Send Reset Code
              </button>
              <button
                type="button"
                onClick={() => setIsForgotPasswordMode(false)}
                className="w-full py-3 bg-gray-500 text-white font-bold rounded-lg hover:bg-gray-600 transition mt-4"
              >
                Back to Login
              </button>
            </form>
          ) : !isResetCodeVerified ? (
            <form onSubmit={handleVerifyResetCode} className="space-y-4">
              <div className="relative">
                <span className="absolute left-3 top-3">
                  <FaCheck className="text-gray-500" />
                </span>
                <input
                  type="text"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                  placeholder="Reset Code"
                  value={resetCode}
                  onChange={(e) => setResetCode(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition"
              >
                Verify Reset Code
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsForgotPasswordMode(false);
                  setIsResetCodeSent(false);
                }}
                className="w-full py-3 bg-gray-500 text-white font-bold rounded-lg hover:bg-gray-600 transition mt-4"
              >
                Back to Forgot Password
              </button>
            </form>
          ) : (
            <form onSubmit={handleSetNewPassword} className="space-y-4">
              <div className="relative">
                <span className="absolute left-3 top-3">
                  <FaLock className="text-gray-500" />
                </span>
                <input
                  type="password"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="relative">
                <span className="absolute left-3 top-3">
                  <FaLock className="text-gray-500" />
                </span>
                <input
                  type="password"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition"
              >
                Set New Password
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsForgotPasswordMode(false);
                  setIsResetCodeSent(false);
                  setIsResetCodeVerified(false);
                }}
                className="w-full py-3 bg-gray-500 text-white font-bold rounded-lg hover:bg-gray-600 transition mt-4"
              >
                Back to Login
              </button>
            </form>
          )
        ) : isRegisterMode ? (
          <form onSubmit={handleRegister} className="space-y-4">
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaUser className="text-gray-500" />
              </span>
              <input
                type="text"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Username (Arabic)"
                value={usernameAr}
                onChange={(e) => setUsernameAr(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaUser className="text-gray-500" />
              </span>
              <input
                type="text"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Username (English)"
                value={usernameEn}
                onChange={(e) => setUsernameEn(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaEnvelope className="text-gray-500" />
              </span>
              <input
                type="email"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaLock className="text-gray-500" />
              </span>
              <input
                type="password"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaLock className="text-gray-500" />
              </span>
              <input
                type="password"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Confirm Password"
                value={confirmPW}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition"
            >
              Register
            </button>
            <button
              type="button"
              onClick={() => setIsRegisterMode(false)}
              className="w-full py-3 bg-gray-500 text-white font-bold rounded-lg hover:bg-gray-600 transition mt-4"
            >
              Back to Login
            </button>
          </form>
        ) : (
          <form onSubmit={handleLogin} className="space-y-4">
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaEnvelope className="text-gray-500" />
              </span>
              <input
                type="email"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Your Email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                required
              />
            </div>
            <div className="relative">
              <span className="absolute left-3 top-3">
                <FaLock className="text-gray-500" />
              </span>
              <input
                type="password"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 transition"
                placeholder="Your Password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition"
            >
              Login
            </button>
            <button
              type="button"
              onClick={() => setIsRegisterMode(true)}
              className="w-full py-3 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition mt-4"
            >
              Register
            </button>
            <button
              type="button"
              onClick={() => setIsForgotPasswordMode(true)}
              className="w-full py-3 bg-gray-500 text-white font-bold rounded-lg hover:bg-gray-600 transition mt-4"
            >
              Forgot Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Register;
