import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-black text-white py-6 mt-12 px-4 border-t border-gray-800">
        <div className="container mx-auto flex flex-col items-center border-red-500">
          <img className="w-56 mb-4" src='../images/logo_blanc.png' alt='TULIB Logo' />
          <p className="mb-2 text-center text-lg">الحدث في حينه</p>

          <div className="flex flex-col items-center space-y-2 mb-4">
            <div className="flex items-center">
              <i className="fa fa-envelope mr-2 ml-2"></i>
              <span>tulibnews@gmail.com</span>
            </div>
            <div className="flex items-center">
            <i className="fa fa-phone mr-2 ml-2"></i>
            <span dir="ltr">(+216) 23 050 630</span>
            </div>
            <div className="flex items-center">
            <i className="fa fa-phone mr-2 ml-2"></i>
            <span dir="ltr">(+218) 946 986 575</span>
            </div>

          
          </div>

          <Link to="/about-us" className='text-red-400 text-lg mt-2'>
            {t("About Us")}
          </Link>
        </div>
      </footer>

      <div className="legal bg-black text-gray-400 py-2 border-t border-red-500">
      <div className="container mx-auto text-center p-4">
  <p className="text-sm sm:text-base">© All rights reserved</p>
  <p className="mt-2 text-sm sm:text-base">
    Developed by Lead Dev Team{' '}
    <FontAwesomeIcon icon={faLaptopCode} className="inline-block" />{' '}
    <a
      href="mailto:lead.dev.team@gmail.com?subject=Subject%20Here&body=Your%20message%20here."
      className="text-blue-400 hover:underline"
    >
      lead.dev.team@gmail.com
    </a>
  
  </p>
</div>

      </div>
    </>
  );
}

export default Footer;
