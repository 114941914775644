import React from "react"
import Side from "../../sideContent/side/Side"

import Popular from "../popular/Popular"
import "./style.css"

const Homes = () => {
  return (
    <>
      <main className="flex justify-center">
        <div className='container'>
          <section className='mainContent'>
            <Popular />
            
          </section>
          <section className='sideContent'>
            <Side />
          </section>
        </div>
      </main>
    </>
  )
}

export default Homes
