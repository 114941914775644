import React, { useRef, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const DayNews = () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const tickerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollSpeed = 1; // Adjust scroll speed here

  // Fetch news items
  useLayoutEffect(() => {
    axios.get(`${API_URL}/api/articles/Day/News`)
      .then(res => setItems(res.data))
      .catch(err => console.log(err));
  }, []);

  // Handle the scrolling
  useLayoutEffect(() => {
    const ticker = tickerRef.current;
    let animationFrameId;

    const animateScroll = () => {
      setScrollPosition(prev => {
        let newPosition = isArabic ? prev + scrollSpeed : prev - scrollSpeed;

        // Adjust scroll position to create an infinite loop
        if (isArabic) {
          if (newPosition >= ticker.scrollWidth / 2) {
            newPosition = 0;
          }
        } else {
          if (newPosition <= -ticker.scrollWidth / 2) {
            newPosition = 0;
          }
        }

        return newPosition;
      });

      animationFrameId = requestAnimationFrame(animateScroll);
    };

    // Reset scroll position based on language
    if (ticker) {
      ticker.scrollLeft = isArabic ? ticker.scrollWidth / 2 : 0;
    }

    animationFrameId = requestAnimationFrame(animateScroll);

    return () => cancelAnimationFrame(animationFrameId);
  }, [isArabic, items]); // Depend on isArabic and items to handle language change

  return (
    <div className="relative p-0 overflow-hidden rounded-lg">
      <div className="absolute inset-0 flex items-center text-base justify-start z-10">
        <span
          className=" text-sm sm:text-base md:text-lg lg:text-xl text-white px-4 py-2 rounded-lg h-10 w-15 font-semibold"
          style={{ backgroundColor: '#08430F' }}
        >
          {isArabic ? 'أخبار اليوم' : 'Day News'}
        </span>
      </div>

      <div className="relative overflow-hidden whitespace-nowrap bg-gray-200 ">
        <div
          ref={tickerRef}
          className="relative flex whitespace-nowrap  py-4"
          style={{
            transform: `translateX(${scrollPosition}px)`,
            direction: isArabic ? 'rtl' : 'ltr',
            display: 'flex',
          }}
        >
          {items.concat(items).map((item, index) => (
            <div key={index} className="px-4 py-2 mx-2 text-lg font-semibold">
              {isArabic ? item.title.ar : item.title.en}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DayNews;
