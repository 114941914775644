import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const UserProfileEdit = ({ user }) => {
  const [formData, setFormData] = useState({
    usernameAr: user.username.ar || '',
    usernameEn: user.username.en || user.username,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    image: null,
  });
  const { setUser } = useAuth(); // Destructure setUser from the useAuth hook

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append('username[ar]', formData.usernameAr);
    data.append('username[en]', formData.usernameEn);
    if (formData.oldPassword) data.append('oldPassword', formData.oldPassword);
    if (formData.newPassword) data.append('newPassword', formData.newPassword);
    if (formData.confirmPassword) data.append('confirmPassword', formData.confirmPassword);
    if (formData.image) data.append('image', formData.image);

    try {
      const response = await axios.put(`${API_URL}/api/editProfile`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('token'),
        },
      });
      setMessage('Profile updated successfully!');
      setMessageType('success');
      // console.log(response.data)
      setUser(response.data)
      // Optionally, reset formData if needed
      setFormData({
        usernameAr: response.data.username.ar || '',
        usernameEn: response.data.username.en || '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        image: null,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Failed to update profile. Please try again.');
      setMessageType('error');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg max-w-lg w-full overflow-hidden">
        <div className="flex flex-col items-center p-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">Edit Profile</h2>
          
          {message && (
            <div className={`w-full p-4 mb-6 text-center ${messageType === 'success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'} border border-${messageType === 'success' ? 'green' : 'red'}-300 rounded-md`}>
              {message}
            </div>
          )}

          <div className="flex items-center justify-center mb-6 flex-col sm:flex-row">
            <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-200">
              <img
                src={user.image ? `${API_URL}/${user?.image.replace('\\', '/')}` :''} // Existing profile image
                alt="Old Profile"
                className="w-full h-full object-cover"
              />
            </div>
            {formData.image && (
              <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-200 mt-4 sm:mt-0 sm:ml-4">
                <img
                  src={URL.createObjectURL(formData.image)} // Preview of new image
                  alt="New Profile"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>

          <input
            type="file"
            onChange={handleFileChange}
            className="mb-6 text-gray-700"
          />

          <form onSubmit={handleSubmit} className="w-full space-y-4">
            <div>
              <label htmlFor="usernameAr" className="block text-sm font-medium text-gray-600 mb-1">Arabic Username</label>
              <input
                type="text"
                name="usernameAr"
                value={formData.usernameAr}
                onChange={handleChange}
                placeholder="Arabic Username"
                className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="usernameEn" className="block text-sm font-medium text-gray-600 mb-1">English Username</label>
              <input
                type="text"
                name="usernameEn"
                value={formData.usernameEn}
                onChange={handleChange}
                placeholder="English Username"
                className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-600 mb-1">Current Password</label>
              <input
                type="password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
                placeholder="Current Password"
                className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600 mb-1">New Password</label>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="New Password"
                className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600 mb-1">Confirm New Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm New Password"
                className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md hover:bg-blue-700 transition"
            >
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileEdit;
