import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const CategoryCards = () => {
  const { category } = useParams();
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const articlesPerPage = 9;
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/articles/news/category`, {
          params: {
            category,
            page: currentPage,
            limit: articlesPerPage,
          },
        });
        setArticles(response.data.articles);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, [category, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [sponsors, setSponsors] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const extractVideoId = (url) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/sponsors/allActiveSponsors`)
      .then((res) => {
        setSponsors(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sponsors.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [sponsors.length]);

  const isRTL = i18n.dir() === 'rtl';

  return (
    <>
    <Helmet>
    <title>{`${t(category)} - ${i18n.language === 'ar' ? 'توليب نيوز' : 'Tulibnews'}`}</title>
    <meta name="description" content={`Browse the latest articles in ${t(category)} on our website.`} />
    <meta name="keywords" content={`${t(category)}, news, articles, ${i18n.language === 'ar' ? 'اخبار' : 'news'}, ${i18n.language === 'ar' ? 'مقالات' : 'articles'}, ${i18n.language === 'ar' ? 'تصنيفات' : 'categories'}`} />
    <link rel="canonical" href={`https://www.tulibnews.com/category/${category}`} />
    <meta property="og:title" content={`${t(category)} - Your Website Name`} />
    <meta property="og:description" content={`Explore the latest articles in ${t(category)} on our website.`} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://www.tulibnews.com/category/${category}`} />
    <meta property="og:image" content={`http://yourwebsite.com/static/images/${category}.jpg`} />
    <meta property="og:locale" content={i18n.language === 'ar' ? 'ar_AR' : 'en_US'} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={`${t(category)} - Your Website Name`} />
    <meta name="twitter:description" content={`Explore the latest articles in ${t(category)} on our website.`} />
    <meta name="twitter:image" content={`http://yourwebsite.com/static/images/${category}.jpg`} />
  </Helmet>
    <div className="container mx-auto p-6 bg-gradient-to-b from-gray-100 to-gray-200 mt-4 rounded-xl shadow-lg">
      <div>
        <h2 className="lg:text-5xl sm:text-2xl font-bold mb-10 text-gray-800 text-center uppercase tracking-widest border-b-4 border-custom-dark-blue pb-2 animate-borderPulse"
        style={{ letterSpacing: 'normal', wordSpacing: 'normal' }}>
          {t(category)}
        </h2>
        <div className="w-full lg:w-2/3 mx-auto mb-10">
          <div className="relative w-full overflow-hidden rounded-lg shadow-lg">
            <div
              className="flex transition-transform duration-700 ease-in-out"
              style={{
                transform: `translateX(${isRTL ? currentSlide * 100 : -currentSlide * 100}%)`,
              }}
            >
              {sponsors.map((src, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <img
                    src={`${API_URL}/${src.replace("\\", "/")}`}
                    alt={`Slide ${index}`}
                    className="w-full object-cover rounded-lg shadow-md"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {articles.map((article) => (
          <Link to={`/singlepage/${article._id}`} key={article._id}>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 flex flex-col h-full tracking-widest border-b-4 border-custom-dark-blue pb-2 animate-borderPulse">
              {category === 'VideoGraphs' ? (
                <img
                  src={article.videoUrl ? `https://img.youtube.com/vi/${extractVideoId(article.videoUrl)}/hqdefault.jpg` : ""}
                  alt={i18n.language === 'ar' ? article.title.ar : article.title.en}
                  className="w-full h-48 object-cover mb-4"
                />
              ) : (
                <img
                  src={article.image ? `${API_URL}/${article.image.replace("\\", "/")}` : ""}
                  alt={i18n.language === 'ar' ? article.title.ar : article.title.en}
                  className="w-full h-48 object-cover mb-4"
                />
              )}
              <div className="p-4 flex-grow">
                <h3 className="text-xl font-semibold text-gray-800"
                  style={{ letterSpacing: 'normal', wordSpacing: 'normal' }}>
                  {i18n.language === 'ar' ? article.title.ar : article.title.en}
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Responsive Pagination */}
      <div className="flex justify-center items-center mt-12 flex-wrap space-x-2">
          {/* Previous Button */}
          <button
            className={`px-4 py-2 border rounded-lg transition-all duration-200 ${currentPage === 1
              ? 'border-gray-300 text-gray-400 cursor-not-allowed'
              : 'border-blue-500 text-blue-500 hover:bg-blue-100 hover:border-blue-600'
              }`}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {t('previous')}
          </button>

          {/* First Page Button */}
          {currentPage > 3 && (
            <>
              <button
                className={`px-4 py-2 border rounded-lg transition-all duration-200 ${currentPage === 1
                  ? 'border-blue-600 bg-blue-600 text-white'
                  : 'border-gray-400 text-gray-700 hover:bg-gray-100'
                  }`}
                onClick={() => handlePageChange(1)}
              >
                1
              </button>
              <span className="px-3">...</span>
            </>
          )}

          {/* Middle Pages */}
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            if (page >= currentPage - 2 && page <= currentPage + 2 && page !== 1 && page !== totalPages) {
              return (
                <button
                  key={page}
                  className={`px-4 py-2 border rounded-lg transition-all duration-200 ${currentPage === page
                    ? 'border-blue-600 bg-blue-600 text-white'
                    : 'border-gray-400 text-gray-700 hover:bg-gray-100'
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              );
            }
            return null;
          })}

          {/* Last Page Button */}
          {currentPage < totalPages - 2 && (
            <>
              <span className="px-3">...</span>
              <button
                className={`px-4 py-2 border rounded-lg transition-all duration-200 ${currentPage === totalPages
                  ? 'border-blue-600 bg-blue-600 text-white'
                  : 'border-gray-400 text-gray-700 hover:bg-gray-100'
                  }`}
                onClick={() => handlePageChange(totalPages)}
              >
                {totalPages}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            className={`px-4 py-2 border rounded-lg transition-all duration-200 ${currentPage === totalPages
              ? 'border-gray-300 text-gray-400 cursor-not-allowed'
              : 'border-blue-500 text-blue-500 hover:bg-blue-100 hover:border-blue-600'
              }`}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {t('next')}
          </button>
        </div>



    </div>
    </>
  );
};

export default CategoryCards;
