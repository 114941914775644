import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './input.css'
import { AuthProvider } from './components/AuthContext';
import {HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <HelmetProvider>
      <App />
      </HelmetProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
