import React, { useEffect, useState } from "react"
import { hero } from "../../../dummyData"
import "./hero.css"
import Card from "./Card"
import axios from "axios"
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const Hero = () => {

  const [items, setIems] = useState(hero)
  useEffect(()=>{
    axios.get(`${API_URL}/api/articles/confirmed/News`)
    .then(res=>{
      // const lastFourItems = res.data.slice(-4).reverse(); // Get the last 4 items
      // console.log(res.data)
      setIems(res.data);
    })
    .catch(err=>console.log(err))
  },[])

  return (
    <>
      <section className='hero'>
        <div className='container'>
          {items.map((item) => {
            return (
              <>
                <Card key={item._id} item={item} />
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Hero
