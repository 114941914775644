import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './urgent.css'; // Ensure Tailwind CSS is imported correctly
import { useTranslation } from 'react-i18next';
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;

const UrgentNews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionState, setTransitionState] = useState('enter');
  const [newsList, setNewsList] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    axios.get(`${API_URL}/api/articles/urgent/News`)
      .then(res => {
        const lastFourItems = res.data.slice(-4).reverse(); // Get the last 4 items
        setNewsList(lastFourItems);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (newsList.length === 0) return; // Avoid setting up interval if there are no news items

    const interval = setInterval(() => {
      setTransitionState('exit');
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % newsList.length);
        setTransitionState('enter');
      }, 600); // Duration of the animation
    }, 8000); // Interval to change news

    return () => clearInterval(interval);
  }, [newsList]);

  return (
    <div className="flex items-center justify-between gap-4 py-2">
      {/* Logo Container */}
      <div className="w-2/12 lg:w-24 flex-shrink-0">
        <img src="../images/TULIB.png" alt="Logo" className="w-full h-auto" />
      </div>

      {/* Urgent News Container */}
      <div
        className=" relative flex items-center p-2 rounded-lg shadow-md h-20 w-full max-w-9xl"
        style={{ backgroundColor: 'rgb(184, 0, 0)' }}
      >
      <div className="flex items-center gap-2 w-full h-full">
        {/* Breaking News Label */}
        <div className=" px-2 py-1 bg-white text-red text-center flex items-center justify-center font-bold text-sm lg:text-lg">
          <span>{i18n.t('Breaking')}</span>
        </div>

        {/* News Content */}
        <div className="flex-1 overflow-hidden">
          {newsList.length > 0 ? (
            <div
              className={`transition-transform duration-700 ${transitionState === 'enter' ? 'animate-slide-in' : 'animate-slide-out'}`}
            >
              <p className="text-base lg:text-xl text-white text-center">
                {i18n.language === 'ar' ? newsList[currentIndex].title.ar : newsList[currentIndex].title.en}
              </p>
            </div>
          ) : (
            <p className="text-sm lg:text-lg text-white text-center"></p>
          )}
        </div>
      </div>
    </div>
    </div >
  );
};

export default UrgentNews;
